.numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  justify-items: center;
  background-color: var(--gray-900);
  column-gap: 20px;
}

.numpadButton {
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  background-color: var(--gray-900);
  color: #fff;
  border: none;
  font-weight: 800;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 12px;
}

.presets {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  gap: 10px;
}

.presetButton {
  display: flex;
  align-items: center;
  all: unset;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  background-color: var(--gray-700);
  padding: 6px 15px;
  border-radius: 50px;
}
