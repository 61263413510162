.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 100000 !important;
}

button {
  font-family: "SF-Pro-Regular", sans-serif !important;
}

.loader {
  width: 34px;
  height: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.popUp {
  width: 546px;

  border-radius: 10px;
  gap: 10px;
  background: #171717;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;

  /* border: 2px solid #303030; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px;

  @media (max-width: 580px) {
    z-index: 999999;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  }

  & > img {
    margin-bottom: 20px;
    height: 52px;
    width: auto;
  }

  & > h1 {
    font-size: 30px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    /* font-weight: 500; */
    text-align: center;
    width: 100%;
  }

  & > input {
    width: 100%;
    height: 52px;
    border-radius: 20px;
    border: 1px solid var(--yellow-primary);
    background: none;
    text-align: center;
    color: var(--white);
    font-size: 20px;
    padding-inline: 1.5rem;

    text-align: left;

    &:focus {
      outline: none;
      border: 2px solid var(--yellow-primary);
    }
  }

  & > p {
    text-transform: capitalize;
    color: var(--gray);
    font-size: 16px;
    padding-left: 5px;
  }

  & button {
    width: 100%;
    height: 52px;
    border-radius: 20px;
    border: none;

    margin-top: 20px;
    margin-bottom: 15px;
    background: var(--yellow-primary);

    & > h1 {
      font-size: 22px;
      color: var(--black-primary);
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.poweredByContainer {
  display: flex;
  align-items: center;
  color: var(--gray);
  font-size: 16px;
}
