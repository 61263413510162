.main {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
	z-index: 20;
}

/* .popUpOverrides {
	@media (max-width: 480px) {
		bottom: 10vh !important;
	}
} */

.popUp {
	box-sizing: border-box;
	display: flex;
	/* width: 390px; */
	border-radius: 10px;

    background-color: var(--gray-900);

	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	flex-direction: column;
	justify-content: flex-start;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;

	@media (max-width: 480px) {
		justify-content: center;
	}

	/* @media (max-width: 480px) {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: fixed;
		bottom: 0;
		left: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	} */
}
