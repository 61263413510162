/* Modal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.modalContent {
  position: fixed; /* Make modal fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  background: #1a1a1a; /* Dark background for modal */
  color: #fff; /* White text */
  padding: 30px;
  border-radius: 12px; /* Rounded corners */
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .modalContent {
    max-width: 100%;
    width: 100%; /* Full width on mobile */
    border-radius: 20px; /* Remove border-radius for full width */
    top: auto; /* Remove top positioning */
    bottom: 0; /* Stick to bottom */
    left: 0; /* Align to the left */
    transform: none; /* Remove transform for full width */
  }
}

.modalContent h2 {
  margin-bottom: 30px;
}

.modalContent button {
  margin-top: 20px;
  padding: 15px;
  background: var(--yellow-primary);
  color: var(--black-primary);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
  font-size: 18px;
}

.networkList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.networkChip {
  display: flex;
  align-items: center;
  background-color: var(--gray-700);
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.networkChip:hover {
  background: var(--gray-600);
}

.networkChip img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.globeIcon {
  font-size: 30px;
  margin-bottom: 10px;
  background-color: var(--gray-700);
  border-radius: 10px;
  width: 40px;
}
