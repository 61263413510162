.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 10000;
}

.chartContainer {
    width: 100%;
    height: auto;
    border-width: 10px;
    justify-content:center;
    align-items: center;

    &>div.chartbox {
        width: 60%;
        height:60%;
        margin-left: 4rem;
    }
    
}

.popUp {
    width: 390px;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 4rem;
    border-radius: 10px;

    background: var(--black-bg);
    background: var(--transparent-black);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    border: 2px solid #303030;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 480px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 0;
        /* Add this line */
        border-bottom-right-radius: 0;
        /* Add this line */
    }

    /*padding-inline: 25px;*/

    &>* {
        padding-inline: 25px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 40px;

    &>button {
        position: absolute;
        left: 25px;
        background: none;
        border: none;
    }

    &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    &>div>img {
        height: 37px;
        width: 37px;
    }

    &>div>p {
        font-size: 1.3rem;
        font-weight: 500;
        color: var(--white);
    }
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--dark-gray);

    &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        background: var(--black);
        border-radius: 100%;

        &>img {
            width: 14px;
            height: auto;
        }
    }

    &>p {
        font-size: 1rem;
        font-weight: 500;
        color: var(--gray);
        margin-top: 10px;
        letter-spacing: 0.5px;
    }
}

.bottom {
    margin-top: 20px;

    &>h2 {
        font-size: 1.3rem;
        letter-spacing: 1px;
        margin-bottom: 25px;
    }
}

.networksCon {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.network {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        &>img {
            height: 40px;
            width: 40px;
        }

        &>div {
            &>h2 {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5px;
                font-size: 0.8rem;
                letter-spacing: 0.5px;
                color: var(--light-gray);
            }
        }
    }

    .right {
        display: flex;
        align-items: end;
        justify-content: center;
        flex-direction: column;

        &>h4 {
            font-size: 1.1rem;
            font-weight: 500;
            color: var(--gray);
        }

        &>p {
            font-size: 0.8rem;
            color: var(--light-gray);
        }
    }
}
