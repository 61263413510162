.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 10000;
}

.popUpOverrides {
	@media (max-width: 480px) {
		bottom: 10vh !important; /* Position the popup at the bottom of the screen */
	}
}

.popUp {
    width: 390px;
    height: fit-content;
    padding-top: 2rem;
    border-radius: 10px;

    background: var(--black-bg);
    background: var(--transparent-black);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    border: 2px solid #303030;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 480px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 0;
        /* Add this line */
        border-bottom-right-radius: 0;
        /* Add this line */
    }

    /*padding-inline: 25px;*/

    &>* {
        padding-inline: 25px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 40px;

    &>button {
        position: absolute;
        left: 25px;
        background: none;
        border: none;
    }

    &>p {
        font-size: 1.3rem;
        font-weight: 500;
        color: var(--white);
    }
}

.top {
    margin-top: 30px;
    gap: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--dark-gray);

    /* &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border-radius: 10px;
        width: 187px;
        height: 43px;
        background: var(--white);
        color: var(--dark-gray);

        & button {
            background: none;
            border: none;
        }
    } */
}
.bottom {
    margin-top: 20px;

    &>h2 {
        font-size: 1.3rem;
        letter-spacing: 1px;
        margin-bottom: 25px;
    }
    margin-bottom: 2rem;
}

.clipboardDiv {
    width: 100%;
    height: auto;
}

.clipboardDiv:hover {
    background-color: grey;
    cursor: pointer;
}

/* .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 5rem;

    &>p {
        font-size: 1.2rem;
        color: var(--gray);
        text-align: center;
    }

    &>img {
        margin-top: 20px;
    }
} */

.networksCon {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.network:hover {
    opacity: 0.6;
}

.network {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        &>img {
            height: 40px;
            width: 40px;
        }

        &>div {
            &>h2 {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5px;
                font-size: 0.8rem;
                letter-spacing: 0.5px;
                color: var(--light-gray);
            }
        }
    }

    .right {
        display: flex;
        align-items: end;
        justify-content: center;
        flex-direction: column;

        &>h4 {
            font-size: 1.1rem;
            font-weight: 500;
            color: var(--gray);
        }

        &>p {
            font-size: 0.8rem;
            color: var(--light-gray);
        }
    }
}


/* Gas payment option styles */
.settingsContainer {
    padding: 20px;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .paymentCard {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 2px solid #333;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .paymentCard:hover {
    opacity: 0.6;
  }
  
  .selectedCard {
    border-color: var(--primary);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .paymentCard p {
    margin: 0;
  }