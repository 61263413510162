/* Container for the entire dropdown component */
.container {
	position: relative;
	display: inline-block;
}

/* Common dropdown styling */
.dropdown {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 1px 0 4px;
	color: var(--gray-50);
	background-color: var(--gray-800);
	border: 1px solid var(--gray-800);
	border-radius: 18px;
	cursor: pointer;
	transition: background-color 0.1s ease;
	font-family: "SF-Pro-Regular" !important;
	transition: all 150ms ease-in-out;
	&:hover {
		border-color: var(--gray-600);
	}
}

/* Style for the dropdown items in the menu */
.dropdownMenu {
	background: var(--gray-800);
	color: var(--gray-100);
	list-style-type: none;
	margin: 8px 0 0;
	padding: 0;
	width: 240%;
	right: 0;
	border-radius: 8px;
	position: absolute;
	z-index: 10;
	max-height: 300px;
	/* overflow-y: scroll; */
	text-align: center;
}

.options {
	overflow-y: scroll !important;
	max-height: 240px;
}

/* Style for individual dropdown items */
.dropdownItem {
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 0.3s ease;

	& > img {
		width: 24px; /* Adjust as needed */
		height: 24px; /* Adjust as needed */
		margin-right: 8px;
	}

	/* Set border radius bottom */
	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

/* Dropdown item hover effect */
.dropdownItem:hover {
	background-color: var(--gray-700);
	color: var(--gray-50) !important;
}

/* Styling for the token logos */
.tokenLogo2box img.tokenLogo {
	width: 24px; /* Adjust as needed */
	/* height: 24px; */
	margin-right: 8px;
	padding-top: 4px;
}

.dropdownArrowBox {
	/* padding-top: 8px; */
	padding-left: 5px;
	padding-right: 5px;
}
/* Styling for the token address display */
.tokenAddress {
	margin-top: 15px;
	padding: 10px;
	width: 100%;
	background-color: #2c2c2c;
	border-radius: 8px;
	text-align: center;
	color: #fff;
	font-weight: bold;
}

.tokenDetails {
	display: flex;
	align-items: center;
	cursor: pointer;
	text-align: left;
	max-width: 80%;
	& > img {
		width: 24px; /* Adjust as needed */
		height: 24px; /* Adjust as needed */
		margin-right: 8px;
	}
	/* border: 1px solid red; */
}

/* Style for the selected token/network text */
.dropdown span {
	color: var(--gray-50);
	margin-right: 8px;
}

.dropdownMenu input {
	all: unset;
	box-sizing: border-box;
	text-align: left;
	padding: 10px !important;
	margin: 10px;
	background: var(--gray-900);
	margin-left: auto;
	margin-right: auto;
	width: 92%;
	border-radius: 8px;
}
