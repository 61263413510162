.sidebarWrapper {
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
	@media (max-width: 720px) {
		display: none;
	}
}
.sidebar {
	position: fixed;
	z-index: 10;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 20px;
	width: var(--sidebar-width);
	background-color: var(--gray-800);
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.logoWrapper {
	margin: 20px;
	max-height: 36px;
}
.navLink {
	text-decoration: none;
	color: inherit; /* Inherit the color from the parent element */
	display: flex;
	gap: 12px;
	font-size: 18px;
	&:hover {
		color: inherit; /* Ensure the color remains the same on hover */
		display: flex;
		gap: 12px;
		font-size: 18px;
	}
}
