/* Container for the entire dropdown component */
.container {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
}

/* Common dropdown styling */
.dropdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding: 0 1px 0 4px;
	color: var(--gray-50);
	background-color: var(--gray-800);
	border: 1px solid var(--gray-800);
	border-radius: 18px;
	cursor: pointer;
	transition: background-color 0.1s ease;
	font-family: "SF-Pro-Regular" !important;
	transition: all 150ms ease-in-out;
	&:hover {
		border-color: var(--gray-600);
	}
}

/* Style for the dropdown items in the menu */
.dropdownMenu {
	background: var(--gray-800);
	color: var(--gray-100);
	list-style-type: none;
	margin: 8px 0 0;
	padding: 0;
	width: 100%;
	border-radius: 8px;
	position: absolute;
	z-index: 10;
	max-height: 300px;
	overflow-y: scroll;
}

/* Style for individual dropdown items */
.dropdownItem {
	padding: 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s ease;

	& > img {
		width: 24px; /* Adjust as needed */
		height: 24px; /* Adjust as needed */
		margin-right: 8px;
	}
}

/* Dropdown item hover effect */
.dropdownItem:hover {
	background-color: var(--gray-700);
}

/* Styling for the token logos */
.tokenLogo2box img.tokenLogo {
	width: 24px; /* Adjust as needed */
	margin-right: 8px;
	padding-top: 4px;
}

.dropdownArrowBox {
	padding-top: 8px;
}
/* Styling for the token address display */
.tokenAddress {
	margin-top: 15px;
	padding: 10px;
	width: 100%;
	background-color: #2c2c2c;
	border-radius: 8px;
	text-align: center;
	color: var(--gray-50);
	font-weight: bold;
}

/* Style for the selected token/network text */
.dropdown span {
	color: var(--gray-50);
	margin-right: 8px;
}
