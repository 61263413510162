.main {
  display: flex;
  gap: 6px;
  align-items: center;
  border-radius: 1000px;
  border: none;
  background: var(--gray-800);
  /* height: 52px; */
  min-height: 42px;
  padding: 4px 12px 4px 8px;
  border: 1px solid var(--gray-700);

  /* padding-inline: 22px; */
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-family: "SF-Pro-Display", sans-serif !important;

  & > h1 {
    font-size: 18px;
    margin: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & h2 {
      font-size: 16px;
    }
    & p {
      font-size: 12px;
      color: var(--gray-400);
    }
  }

  .loaderImg {
    animation: spin 1.3s linear infinite;
  }
}
