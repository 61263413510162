.addMoneyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  gap: 10px;
}

.ctaButtonWrapper {
  cursor: pointer;
  width: 50%;
  border: 1px solid var(--gray-800);
  background-color: var(--gray-800);
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  gap: 0px;
  border-radius: 50px;
  padding: 15px 20px;
  & span {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    padding: 10px 10px;
    & span {
      font-size: 1.1rem;
    }
  }
}

.qrButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--gray-800);
  border-radius: 50%;
  padding: 20px;
}

/* dashboard.module.css */
.circularIconWrapper {
  width: 30px !important;
  height: 40px !important;
 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-800); /* or any color you prefer */
}
