/* Modal Container */
.disconnectedAccount {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 20px;
  color: white;
}
.page {
  padding: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  span {
    cursor: pointer;
  }
}

.disconnectedAccount svg {
  margin-bottom: 15px;
  color: #888;
}

.disconnectedAccount p {
  font-size: 16px;
  color: #888;
}
/* Modal Box */
/* .modal {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    max-width: 500px;
    width: 100%;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
/* text-align: center;
} */

.modalCon h2 {
  color: white;
}
/* Modal Title */
.modalTitle {
  font-size: 26px;
  margin-bottom: 25px;
  color: white; /* Add this line */
  text-align: center;
}

.qrCode {
  /* ... existing styles ... */
  /* border: 4px solid white; Add this line */
  /* background-color: white; Add this line to ensure a white background */
}

.networksContainer {
  /* border: 1px solid #666; */
  @media (min-width: 720px) {
    margin-left: 10px;
  }

  @media (max-width: 720px) {
    margin-top: 1rem;
  }
}

.networksCon {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  color: white;
}

.accountNetwork:hover {
  opacity: 0.6;
}

.accountNetwork {
  display: flex;
  flex-direction: row;
  align-items: stretch; /* Change this from center to stretch */
  justify-content: space-between;
  cursor: pointer;

  .accountNetworkLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > img {
      height: 40px;
      width: 40px;
    }

    & > div {
      & > h2 {
        gap: 0.5px;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
        color: var(--light-gray);
      }
      & > p {
        margin-bottom: 4px;
      }
    }
  }

  .accountNetworkRight {
    display: flex;
    flex-direction: column !important;
    justify-content: center; /* Change this from end to center */
    align-items: flex-end; /* Change this from center to flex-end */
    height: 100% !important; /* This ensures full height */
  }
}

.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.accountContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.connectedAccount {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;

  /* @media (max-width: 720px) {
    flex-direction: column;
  } */
  /* justify-content: space-between; */
}
.connectedAccount,
.walletConnect {
  flex: 1;
}

.walletConnect {
  padding: 20px;
  border-radius: 8px;
}

.walletConnectInput {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  background-color: #1a1a1a;
  color: white;
  border-radius: 4px;
  font-size: 14px;
}

.walletConnectInput::placeholder {
  color: #999;
}

.walletConnectButton {
  transition: background-color 0.3s ease;
}

.walletConnectButton:hover {
  background-color: #2980b9;
}

.walletConnectButton:active {
  background-color: #2471a3;
}

.copyButton {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: opacity 0.3s ease;

  border-radius: 8px;
  padding: 10px !important;
  max-width: 160px;
  width: 100%;
}

.copyButton:hover {
  opacity: 0.8;
}

.infoBox {
  display: flex;
  align-items: center;
  background-color: rgb(52, 102, 234, 0.2);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid rgb(52, 102, 234);
}

.infoBox p {
  margin-left: 10px;
  font-size: 14px;
  color: rgb(52, 102, 234);
}

.deploymentBadgeContainerMobile {
}

.deploymentBadge {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
  display: block;
  margin-top: 22px !important;
}

.deployed {
  background-color: #4caf50;
  color: white;
}

.notDeployed {
  background-color: #f44336;
  color: white;
}

.pillContainer {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 20px;
  paddiing: 0;
}

.pill {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #666;
  background-color: #333;
  color: #ccc;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.activePill {
  background-color: var(--primary);
  color: white;
  border-color: #007bff;
}

.gasCard {
  display: flex;
  flex-direction: column;
  background-color: #ff770011;
  color: #3395ff;
  border: 1px solid #3395ff;
  padding: 20px;
  border-radius: 10px;

  & > button {
    background-color: #3395ff;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  & > button:hover {
    opacity: 0.8;
  }
}

.copyDiv {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  opacity: 0.7;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.walletContainer {
  padding-top: 70px;
  padding-bottom: 90px;
}

.walletDescription {
  margin-bottom: 10px;
  color: var(--gray-300);
  font-size: 14px;
  width: 300px;
  text-align: center;
}

.showNetworks {
  margin-top: 10px;
  font-size: 14px;
  color: var(--yellow-primary);
  cursor: pointer;
  margin-bottom: 10px;
}
