/* Container for the entire dropdown component */
.container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
}

/* Common dropdown styling */
.dropdown {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--gray-50);
  /* background-color: var(--gray-800); */
  /* border: 1px solid var(--gray-800); */
  /* border: 1px solid red; */
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  font-family: "SF-Pro-Regular" !important;
  transition: all 150ms ease-in-out;
  font-size: 1.1rem;
  &:hover {
    border-color: var(--gray-600);
  }
}

.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 20 !important;
}
/* Style for the dropdown items in the menu */
.dropdownMenu {
  width: 546px;
  height: 428px;
  border-radius: 10px;
  /* border: 2px solid #303030; */
  background: #171717;;
  color: var(--gray-100);
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;
  padding-top: 10px;
  border-radius: 8px;
  position: absolute;
  z-index: 30;
  /* overflow-y: scroll; */
  text-align: center;

  & > input {
    /* width: 100%; */
    height: 52px !important;
    border-radius: 20px !important;
    border: 1px solid var(--yellow-primary) !important;
    background: none !important;
    text-align: center;
    color: var(--white);
    font-size: 20px !important;
    padding-inline: 1.5rem;

    text-align: left;

    &:focus {
      outline: none;
      border: 2px solid var(--yellow-primary) !important;
    }
  }

  @media (max-width: 480px) {
    width: 100%; /* Make the popup fit the width of the screen */
    height: 580px;
    margin-left: auto;
    margin-right: auto;
    position: fixed; /* Fix the position of the popup */
    bottom: 0vh !important;
    left: 0;
    border-bottom-left-radius: 0; /* Add this line */
    border-bottom-right-radius: 0; /* Add this line */
  }

  & > h2 {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    gap: 10px;
  }

  & > h2 > span {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  /* display: fixed; */
}

.options {
  overflow-y: scroll !important;
  max-height: 300px;
  /* max-height: 100 */
  /* max-height: 100%;  */

  @media (max-width: 720px) {
    padding-bottom: 10px;
    max-height: 450px;
  }
}

/* Style for individual dropdown items */
.dropdownItem {
  padding: 12px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;

  /* & > img {
		width: 36px; 
		height: 36px;
		margin-right: 8px;
	} */

  /* Set border radius bottom */
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

/* Dropdown item hover effect */
.dropdownItem:hover {
  background-color: var(--gray-700);
  color: var(--gray-50) !important;
}

/* Styling for the token logos */
.tokenLogo2box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.networkText {
  font-size: 14px;
  color: var(--gray-500);
  margin-top: 4px;
}

.tokenLogo2box img.tokenLogo {
  width: 44px; /* Adjust as needed */
  /* height: 24px; */
  margin-right: 8px;
  padding-top: 4px;
  /* border: 1px solid red; */
  border-radius: 4px;
}

.dropdownArrowBox {
  display: flex;
  align-items: center;
  gap: 4px;
}
/* Styling for the token address display */
.tokenAddress {
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  background-color: #2c2c2c;
  border-radius: 8px;
  text-align: center;
  color: #000000;
  font-weight: bold;
}

.tokenDetails {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  max-width: 80%;
  & > img {
    width: 42px; /* Adjust as needed */
    height: 42px; /* Adjust as needed */
    margin-right: 8px;
  }
  /* border: 1px solid red; */
}

/* Style for the selected token/network text */
.dropdown span {
  color: var(--gray-50);
  margin-right: 8px;
}

.dropdownMenu input {
  all: unset;
  box-sizing: border-box;
  text-align: left;
  padding: 10px !important;
  margin: 10px;
  background: var(--gray-900);
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  border-radius: 8px;
  border: 1px solid #303030;
}

.emptyList {
  /* border: 1px solid red; */
  padding: 10px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: gray;
  font-size: 1.5rem;

  @media (max-width: 720px) {
    height: 400px;
  }
}


.networkLogo {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 6px;
  left: 35px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
}



