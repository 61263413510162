:root {
  --black: #000000;
  --black-bg: #131313;
  --transparent-black: rgba(19, 19, 19, 0.9);

  --white: #ffffff;

  --gray: #a4a4a4;
  --blue-gray: #31323a;
  --light-gray: #686868;
  --dark-gray: #33343d;
  --violet-gray: #73758d;
  --lighter-gray: #eaebef;

  --violet: #8490ff;
  --violet-gray: #555c9f;

  --blue: #4b5ae4;
  --green: #24cc8b;
  --red: #cc384c;
  --lime: #b3e44b;

  --sidebar-width: 280px;
  --topbar-height: 68px;
  --announcement-height: 40px;
  --screen-lg: 1080px;

  --gray-900: #000000;
  --gray-800: #181818;
	--gray-700: #3c3c3c;
	--gray-600: #575757;
	--gray-500: #737373;
	--gray-400: #8e8e8e;
	--gray-300: #aaaaaa;
	--gray-200: #c6c6c6;
	--gray-100: #e1e1e1;
	--gray-50: #fdfdfd;
	--primary: #3466ea;

  /* bento palette */
  --yellow-primary: #fcff61;
  --black-primary: #0f0f0e;
  --violet-primary: #9094d3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.2;
}

body {
  font-family: "SF-Pro-Regular", sans-serif;
  background: var(--gray-900) !important;
  color: var(--gray-50);
  font-size: 16px;
  @media (max-width: 720px) {
    font-size: 14px;
  }
}

@font-face {
  font-family: "SF-Pro-Bold";
  src: url("../public/fonts/SF-Pro-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Medium";
  src: url("../public/fonts/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SF-Pro-Regular";
  src: url("../public/fonts/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../public/fonts/Poppins-Regular.ttf") format("opentype");
}

button {
  cursor: pointer;
  border-radius: 20px;
  transition: transform 0.1s ease-in-out;
  position: relative;
  top: 0;
}

button:active {
  top: 2px;
  transform: translateY(2px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-primary {
  cursor: pointer !important;
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 20px;
  background: var(--yellow-primary);
  color: var(--black-primary) !important;
  text-align: center;
  &.w-full {
    width: 100%;
  }
  &:disabled {
    opacity: 0.8;
    /* filter: grayscale(0.8); */
  }
  @media (hover: hover) {
    &:hover {
      background: var(--gray-600);
      color: white !important;
    }
  }
}

.btn-secondary {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  background: var(--gray-700);
  text-align: center;
  &.w-full {
    width: 100%;
  }
  &:disabled,
  &:hover {
    opacity: 0.8;
    /* filter: grayscale(0.8); */
  }
}

.btn-ghost {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  background: "transparent";
  text-align: center;
  transition: color 0.3s ease-in-out;
  &.sm {
    font-size: 12px;
  }
  &.w-full {
    width: 100%;
  }
  &:hover {
    background: var(--gray-800);
  }
  &:disabled {
    opacity: 0.8;
    /* filter: grayscale(0.8); */
  }
}

.tag {
  all: unset;
  padding: 2px 4px;
  width: fit-content;
  background: var(--gray-700);
  color: var(--gray-100);
  border-radius: 4px;
  font-size: 0.625rem;
  font-weight: 500;
  vertical-align: middle;
  &.green {
    color: var(--green);
  }
  &.red {
    color: var(--red);
  }
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--green);
}
