.main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

button {
  font-family: "SF-Pro-Regular", sans-serif !important;
}

.popUpOverrides {
  @media (max-width: 480px) {
    bottom: 0vh !important; /* Position the popup at the bottom of the screen */
  }
}

.popUp {
  /* min-height: 600px; */
  width: 546px;
  display: flex;
  flex-direction: column;

  /* background: var(--transparent-black); */
  background: #171717;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  padding-inline: 23px;
  padding-block: 30px;
  border-radius: 20px;

  /* border: 2px solid #303030; */

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      & > p {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  & button {
    width: 100%;
    height: 52px;
    border-radius: 20px;
    border: none;
    margin-top: 10px;
    margin-bottom: 15px;
    background: var(--yellow-primary) !important;

    & > h1 {
      font-size: 20px;
    }
  }

  @media (max-width: 580px) {
    width: 100%;
    /* Make the popup fit the width of the screen */
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    /* Fix the position of the popup */
    bottom: 0;
    /* Position the popup at the bottom of the screen */
    left: 0;
    border-bottom-left-radius: 0;
    /* Add this line */
    border-bottom-right-radius: 0;
    /* Add this line */
    max-height: 100%;
    min-height: 0;
  }
}

.logo {
  height: 52px;
  width: auto;
}

.new {
  height: 18px;
}

.heading {
  display: flex;
  justify-content: space-between;

  & > * {
    font-size: 24px;
  }
}

.internalContainer {
  .internalHeading {
    display: flex;
    justify-content: space-between;

    & > * {
      margin-top: 10px;
      font-size: 16px;
    }
  }
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.fromContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    /* margin-left: 8px; */
    font-size: 16px;
  }

  & > h2 {
    margin-left: 8px;

    @media (max-width: 480px) {
      margin-left: 0;
      font-size: 0.9rem;
    }
  }
}

.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .circle {
    height: 20px;
    width: 20px;
    border-radius: 100%;

    @media (max-width: 480px) {
      display: hidden !important;
      height: 0;
      width: 0;
    }
  }

  .lime {
    background: var(--lime);
  }

  .blue {
    background: var(--blue);
  }

  & > h2 {
    font-size: 16px;
    color: var(--gray);
  }

  & > img {
    width: 20px;
    height: auto;
    margin-inline: 1rem;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
  color: var(--gray);

  .bold {
    color: var(--white);
  }

  & > h2 {
    font-size: 19px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > h2 {
      font-size: 18px;
    }
  }
}

.btnCon2,
.btnCon {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  & > * {
    margin-top: 1.5rem;
    height: 52px;
    width: 100%;
    border: none;
    border-radius: 10px;
    background: var(--blue);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all 0.3s;
    cursor: pointer;

    & > * {
      font-size: 18px;
    }

    &:hover .arrow {
      transform: rotate(45deg);
    }
  }

  .arrow {
    width: 13px;
    transform: translateY(15%);
    height: auto;
    transition: all 0.3s;
    mix-blend-mode: difference;
  }

  .loader {
    width: 34px;
    height: auto;
    animation: spin 1s linear infinite;
    mix-blend-mode: difference;
  }
}

.btnCon2 {
  gap: 24px;

  & > *:first-child {
    background: var(--blue-gray);
  }

  & > *:last-child {
    background: var(--white);
    color: var(--dark-gray);
  }
}

.button3 {
  background: var(--white);
  color: var(--dark-gray);
}

.viewInternalToggle {
  color: lightblue;
  margin-left: 10px;
  cursor: pointer;
}

.viewInternalToggle:hover {
  text-decoration: underline;
  opacity: 0.8;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gasError {
  color: #eb4034;
  display: block;
  border: 1px solid #eb4034;
  padding: 6px;
  border-radius: 6px;
  width: 100%;
  text-align: center;
}

.tokenLogoContainer {
  overflow: hidden;
  display: flex;
  align-items: center;

  border-radius: 50%;
}

.tokenLogo {
  width: 50px;
  height: auto;
}

.transactionAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
