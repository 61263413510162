@import "main.css";
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 20;
}

.popUpOverrides {
  @media (max-width: 480px) {
    bottom: 10vh !important; /* Position the popup at the bottom of the screen */
  }
}

.popUp {
  box-sizing: border-box;
  display: flex;
  width: 390px;
  /* height: fit-content; */
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 10px;

  background: var(--black-bg);
  background: var(--transparent-black);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border: 2px solid #303030;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      & > p {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 0;
    /* Add this line */
    border-bottom-right-radius: 0;
    /* Add this line */
  }

  /* padding-inline: 25px; */

  & > * {
    padding-inline: 25px;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.headerTitle {
  display: flex;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: "SF-Pro-Regular";

  & > div > img {
    width: 32px !important;
  }
}

.header2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  & > div {
    display: flex;
    gap: 0rem;

    & > * {
      width: 55px;
      height: 55px;
      background: var(--black-bg);
      border-radius: 100%;
      border: none;

      & > * {
        height: 18px;
        width: auto;
      }
    }
  }
}

.bell {
  background: none !important;
}

.bell img,
.header img {
  width: 18px;
}

.transferCard {
  width: 100%;
  border-radius: 15px;
  /* padding: 1.5rem; */
  margin-bottom: 2rem;
}

.transferSection {
  width: 100%;
  height: 10rem;
  margin-bottom: 0.2rem;
  background-color: #2f2f2f;
  padding: 0px 10px 10px 10px;
  padding-top: 20px;
  border-radius: 18px;
}

.sectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & > span {
    margin-right: 10px;
    font-size: 12px;
    color: #666666;
  }
}

.dropdown {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  & > img {
    height: 28px;
    width: auto;
  }

  & > span {
    font-size: 12px;
    color: #ffffff;
    font-family: "Poppins-Regular";
    font-weight: 600;
  }

  & > div {
    height: 30px;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 5px 5px 5px 5px;

    & > img {
      height: 8px;
      width: auto;
    }
  }
}

.tokenDropdown {
  background-color: #2c2c2c;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;

  & > img {
    height: 28px;
    width: auto;
  }

  & > span {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins-Regular";
  }

  & > div {
    height: 30px;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 5px 5px 5px 5px;

    & > img {
      height: 8px;
      width: auto;
    }
  }
}

.sectionContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1rem; */
  border-radius: 10px;
  /* background-color: green; */
}

.sectionContent2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1rem; */
  border-radius: 10px;
  /* background-color: green; */
  background-color: #1d1d1d;

  & > input {
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 100%;
    height: 30px;
    font-size: 12px;
    align-items: center;
    text-transform: lowercase;
    border: none;
    background: transparent;
    outline: none;
    color: #e6e6e6;
    font-family: Poppins-Regular;
    font-weight: 600;
  }
}

.balanceContainer {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  flex-direction: column;

  & > input {
    width: 80%;
    font-size: 30px;
    text-transform: lowercase;
    border: none;
    background: transparent;
    outline: none;
    color: #e6e6e6;
    font-family: Poppins-Regular;
    font-weight: 600;
  }

  & > p {
    text-align: left;
    font-weight: 600;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #666666;
  }
}

.sectionContent h2 {
  font-size: 2rem;
}

.sectionContent p {
  color: #a3a3a3;
}

.transferArrow {
  display: flex;
  text-align: center;
  /* margin-bottom: 1rem; */
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
}

.transferArrowbox {
  width: 20px;
  text-align: center;
  /* margin-bottom: 1rem; */
  background-color: #000;
  justify-content: center;
  align-items: center;
  padding: 2px 5px 2px 5px;
  /* margin-left: 170px; */
  border-radius: 4px;
  position: absolute;
  top: 26.3rem;
}

.transferArrowbox img {
  width: 10px;
  height: 10px;
}

.address {
  background-color: #1d1d1d;
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
  color: #616161;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins-Regular";
}

.sendButton {
  background-color: white;
  color: black;
  padding: 1rem;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
  font-size: 18px;
  font-family: "SF-Pro-Regular";
}

.sendButton:hover {
  background-color: #f1f1f1;
}

/* New SWAP UI */
.swapInputWrapper {
  background-color: var(--gray-900);
  border-radius: 12px;
  padding: 16px;
  transition: all 300ms ease-in-out;
  border: 1px solid var(--gray-900);
  &:hover {
    border-color: var(--gray-700);
  }
  & label {
    display: block;
    font-size: 14px;
    color: var(--gray-500);
    margin: 4px 0;
  }
  & small {
    display: inline-block;
    font-size: 14px;
    color: var(--gray-500);
    margin-top: 4px;
  }
}
.swapInput {
  all: unset;
  font-size: 32px;
  color: var(--gray-50);
  min-width: 0;
  flex: 1;
}
.switchButton {
  border-radius: 12px;
  height: 40px;
  width: 40px;
  margin: -18px auto;
  position: relative;
  background-color: var(--gray-800);
  & button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  transition: all 300ms ease-in-out;
  border: 1px solid var(--gray-900);
  &:hover {
    border-color: var(--gray-700);
  }
}

.validInput {
  border: 1px solid #4caf50 !important; /* or your preferred success color */
}
