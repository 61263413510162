header {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 5px 16px 12px 16px;
  background: var(--gray-900);
  max-height: 100px;
  .logo-wrapper {
    flex-grow: 1;
    flex-shrink: 0;
    height: 24px;
    @media (min-width: 720px) {
      display: none;
    }
  }
}

.hamburger-btn {
  border: none;
  border: 1px solid var(--gray-700);
  border-radius: 4px;
  color: #fff;
  height: fit-content;
  padding: 4px 4px 0 4px;
  background: transparent;
  @media (min-width: 720px) {
    display: none;
  }
}

#menu-toggle {
  display: none;
}

.mobile-menu {
  position: fixed;
  z-index: 100;
  top: 0;
  left: calc(-1 * var(--sidebar-width));
  width: var(--sidebar-width);
  height: 100%;
  background-color: var(--gray-800);
  display: flex;
  padding: 24px;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
}

#menu-toggle:checked ~ .mobile-menu {
  left: 0;
}

.mobile-menu-overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #17171780;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

#menu-toggle:checked ~ .mobile-menu-overlay {
  opacity: 1;
  visibility: visible;
}
