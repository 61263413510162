.tokenLogoBox {
  position: relative;
  width: 40px;
  height: 40px;
}

.tokenLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.networkLogo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -10px;
  border: 1px solid rgb(14, 14, 14);
  background-color: var(--white);
}

.transactionCard {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--gray-800);
  padding: 1rem;
  border-radius: 20px;

  cursor: pointer;
  margin-top: 13px;
}

.transactionCard:hover {
  background-color: var(--gray-700);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.actionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -10px;
  border: 1px solid var(--black-primary);
  background-color: var(--gray-800);
  color: var(--primary-yellow) !important;
}
