.bottomNavContainer {
  position: fixed;
  width: 100vw;
  z-index: 9;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
  padding: 20px 30px 35px 30px;
  background-image: linear-gradient(transparent, #000000b0, var(--gray-900));

  /* background: blue; */
  max-height: 14vh;

  @media (min-width: 720px) {
    display: none;
  }
}
.bottomNavTokenDetailsContainer {
  position: fixed;
  width: 100vw;
  z-index: 9;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
  padding: 20px 30px 35px 30px;
  background-image: linear-gradient(transparent, #000000b0, var(--gray-900));

  /* background: blue; */
  max-height: 14vh;
}

.navItemsContainer {
  display: flex;
  flex-direction: row;
  gap: 38px;
  background-color: var(--gray-800);
  padding: 8px 24px;
  border-radius: 50px;
}

.navListItem2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  width: 40px;
  /* gap: 12px; */
  font-size: 18px;
  /* padding: 20px; */
  text-decoration: none !important;
  color: #fff;
  border-radius: 10px;
  &.active {
    background: var(--gray-800);
  }
  &.active-blue {
    color: var(--yellow-primary) !important;
  }

  & > span {
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: 8px;
  }
  cursor: pointer;
}

.activeBlue {
  color: #171717 !important;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-900);
  padding: 18px;
  border-radius: 50%;
}

.activeIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--yellow-primary);
  padding: 18px;
  border-radius: 50%;
}

.iconWrapper:active {
  top: 2px;
  transform: translateY(2px);
}
