.nav-list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.nav-list-item {
	display: flex;
	gap: 12px;
	font-size: 18px;
	padding: 20px;
	text-decoration: none;
	color: #fff;
	border-radius: 10px;
	&:hover {
		/* background: var(--gray-700); */
		opacity: 0.8;
	}
	&.active {
		background: var(--gray-700);
	}
	&.active-blue {
		color: var(--primary) !important;
	}
	cursor: pointer;
}
.nav-list-item.active {
	/* color: #fff; */
}
